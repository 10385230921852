import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { navigate } from 'gatsby-link';
import React, { useState } from 'react';
import getProductBySlug from '../graphql/queries/getProductBySlug.graphql';
import Loader from './Loader';
import SingleTrainingContent from './SingleTrainingContent';

export default function SingleETrainingPage({ slug }) {
  const { data, loading } = useQuery(getProductBySlug, {
    variables: { slug },
  });
  const [isRedirecting, setRedirecting] = useState(false);
  if (loading && !data) {
    return <Loader />;
  }
  const product = data.getProductBySlug;
  const ecrkLink = `https://ecrklex.pl/produkty/${product.slug}`;
  const buttonText = isRedirecting
    ? 'Za chwilę nastąpi przekierowanie na stronę ECRK Lex ...'
    : 'Przejdź na stronę ECRK, aby się zapisać';

  return (
    <SingleTrainingContent
      imageSrc={product.imageSrc}
      title={product.title}
      text={product.text}
      setRedirecting={setRedirecting}
      link={ecrkLink}
      buttonText={buttonText}
      redirecting={isRedirecting}
    />
  );
}

SingleETrainingPage.propTypes = {
  slug: PropTypes.string.isRequired,
};
