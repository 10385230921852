import {
  Box,
  Button,
  Flex,
  Heading,
  Image,
  Text,
} from 'rebass/styled-components';
import Interweave from 'interweave';
import React from 'react';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby-link';

export default function SingleTrainingContent(props) {
  return (
    <Flex flexDirection={'column'} variant={'wrapper'} my={20}>
      <Box width={[1 / 2, 1 / 3, 1 / 3]} maxHeight={'320px'}>
        <Image src={props.imageSrc} />
      </Box>
      <Box variant={'singlePageContent'}>
        <Box mx={50} my={20}>
          <Heading fontFamily={'Oswald'} margin={20}>
            {props.title}
          </Heading>
          <Box className={'db-text'}>
            <Interweave content={props.text} />
          </Box>
        </Box>
      </Box>
      <Box mt={40} alignSelf={'center'} width={400}>
        <Button
          onClick={() => {
            props.setRedirecting(true);
            return setTimeout(() => {
              navigate(props.link);
              return null;
            }, 3000);
          }}
          width={'100%'}
        >
          <Text
            className={props.redirecting ? 'loading-text' : ''}
            padding={10}
          >
            {props.buttonText}
          </Text>
        </Button>
      </Box>
    </Flex>
  );
}

SingleTrainingContent.propTypes = {
  imageSrc: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  redirecting: PropTypes.bool.isRequired,
  buttonText: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  setRedirecting: PropTypes.func.isRequired,
};
