import React from 'react';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby-link';
import Layout from '../../components/Layout';
import SingleETrainingPage from '../../components/SingleETrainingPage';

const TrainingPage = ({ slug }) => {
  if (!slug && typeof window !== 'undefined') {
    navigate('/e-szkolenia/strona/0');
    return null;
  }
  return (
    <Layout>
      <SingleETrainingPage slug={slug} trainingType={'e-szkolenia'} />
    </Layout>
  );
};

TrainingPage.propTypes = {
  slug: PropTypes.string.isRequired,
};

export default TrainingPage;
